import websitePage, { HEADER_TYPE_WITH_MENU_AND_APPLICATION } from '../hoc/websitePage/websitePage';
import HomeLite from '../screens/HomeLite/HomeLite';

export default websitePage(HomeLite, {
  routeKey: 'HOME',
  header: HEADER_TYPE_WITH_MENU_AND_APPLICATION,
  btnVisibleScrolling: true,
  buttonLabel: 'HOME.HERO.BUTTON_LABEL.MULTIPRODUCT',
  isWhiteColorHero: false,
});
